import { financeInvoiceService, todoService } from '@/api';
import { OsTableOption, OsTableColumn } from '@/components/os-table/os-table';
import { FinanceInvoiceStatusEnum, InvoiceColorEnum, InvoiceModeEnum, InvoiceTypeEnum } from '@/resource/enum';
import { ApproveParamter, FinanceInvoiceDetail, FinanceInvoiceInfo } from '@/resource/model';
import { TagsViewModule } from '@/store/modules/tags-view';
import { messageError, showWarningConfirm, translation } from '@/utils';
import { Message, TimelineItem } from 'element-ui';
import { MessageBoxInputData } from 'element-ui/types/message-box';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import UploadAttachment from '@/views/finance-management/components/upload-attachment/upload-attachment.vue';

@Component({
  name: 'InvoiceDetails',
  components: { UploadAttachment }
})
export default class InvoiceDetails extends Vue {
  public id = Number(this.$route.query.id);

  public invoice: FinanceInvoiceInfo | null = null;

  public downloadLoading = false;

  public approveLoading = false;

  public tableOptions: OsTableOption<Partial<FinanceInvoiceDetail>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true,
    showSummary: true,
    sumPropsOptions: [
      {
        prop: 'count'
      },
      {
        prop: 'amountExcludingTax',
        fixPlace: 2
      },
      {
        prop: 'amountIncludingTax',
        fixPlace: 2
      },
      {
        prop: 'taxAmount',
        fixPlace: 2
      }
    ]
  };

  public columnOptions: Array<OsTableColumn<FinanceInvoiceDetail>> = [
    {
      type: 'index',
      prop: 'id',
      label: 'common.index',
      width: '80px'
    },
    {
      prop: 'taxRevenueName',
      label: 'billing.taxRevenueName',
      minWidth: '180px'
    },
    {
      prop: 'serviceName',
      label: 'billing.serviceName',
      minWidth: '180px'
    },
    {
      prop: 'priceUnitName',
      label: 'billing.priceUnit',
      minWidth: '180px'
    },
    {
      prop: 'count',
      label: 'billing.priceCount',
      minWidth: '180px',
      formatter: (rowData: Object): string => {
        return (rowData as FinanceInvoiceDetail).count.toFixed(2);
      }
    },
    {
      prop: 'priceBeforeTax',
      label: 'billing.priceBeforeTax',
      minWidth: '180px',
      formatter: (rowData: Object): string => {
        return (rowData as FinanceInvoiceDetail).priceBeforeTax.toFixed(6);
      }
    },
    {
      prop: 'amountExcludingTax',
      label: 'billing.amountExcludingTax',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return (rowData as FinanceInvoiceDetail).amountExcludingTax.toFixed(2);
      }
    },
    {
      prop: 'taxRate',
      label: 'billing.taxRate',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return (rowData as FinanceInvoiceDetail).taxRate.toFixed(2);
      }
    },
    {
      prop: 'taxAmount',
      label: 'billing.taxAmount',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return (rowData as FinanceInvoiceDetail).taxAmount.toFixed(2);
      }
    },
    {
      prop: 'amountIncludingTax',
      label: 'billing.amountIncludingTax',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        return (rowData as FinanceInvoiceDetail).amountIncludingTax.toFixed(2);
      }
    }
  ];

  /**
   * 发票审批流程图
   */
  public approvalFlowChart = '';

  /**
   * 发票审批结果
   */
  public approvalRecords: Array<Partial<TimelineItem & { content: string }>> = [];

  public get showApprovalFlowChart(): boolean {
    return this.approvalFlowChart !== '';
  }

  public get showApprovalCard(): boolean {
    return this.approvalFlowChart !== '' || this.approvalRecords.length > 0;
  }

  public get color(): string {
    return translation(`invoiceColor.${InvoiceColorEnum[this.invoice!.color]}`);
  }

  public get invoiceMode(): string {
    return translation(`invoiceMode.${InvoiceModeEnum[this.invoice!.invoiceMethod]}`);
  }

  public get invoiceType(): string {
    return translation(`invoiceType.${InvoiceTypeEnum[this.invoice!.invoiceType]}`);
  }

  public get statusName(): string {
    return FinanceInvoiceStatusEnum[this.invoice!.status]
      ? translation(`financeInvoiceStatus.${FinanceInvoiceStatusEnum[this.invoice!.status]}`)
      : translation('common.unKnownStatus');
  }

  public get allowApprove(): boolean {
    if (this.invoice?.status === FinanceInvoiceStatusEnum.waitAudit && this.approveParams.pid) {
      return true;
    }
    return false;
  }

  private approveParams = {
    pid: this.$route.query.pid as string,
    taskId: this.$route.query.taskId as string,
    scope: Number(this.$route.query.scope),
    type: Number(this.$route.query.type)
  };

  private dataIsEdited = false;

  public activated(): void {
    if (!isNaN(this.id) && this.id !== Number(this.$route.query.id)) {
      this.id = Number(this.$route.query.id);
      this.loadDetails(this.id);
    }
  }

  public created(): void {
    if (!isNaN(this.id)) {
      this.loadDetails(this.id);
    }
  }

  /**
   * 审批
   */
  public approved(): void {
    showWarningConfirm(translation('todo.confirmApproved'))
      .then(async () => {
        this.setApproveLoading(true);
        const param: ApproveParamter = {
          id: this.id,
          ...this.approveParams,
          comment: '审批通过'
        };
        todoService
          .approved(param)
          .then(() => {
            Message.success(translation('operationRes.operationSuccess'));
            this.dataIsEdited = true;
            this.$router.go(-1);
            TagsViewModule.DelView(this.$route);
          })
          .catch(error => {
            messageError(error);
          })
          .finally(() => {
            this.setApproveLoading(false);
          });
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelOperation'));
      });
  }

  /**
   * 驳回
   */
  public async reject(): Promise<void> {
    try {
      // 提示用户填写驳回原因
      const reasonObj = await this.$prompt(translation('todo.inputRejectReason'), translation('tip.tipInfo'), {
        confirmButtonText: translation('button.ok'),
        cancelButtonText: translation('button.cancel'),
        inputPlaceholder: translation('todo.inputRejectReason'),
        inputValidator: (value: string): boolean | string => {
          if (!value) {
            return translation('todo.inputRejectReason');
          }
          return true;
        }
      });
      const param: ApproveParamter = {
        id: this.id,
        ...this.approveParams,
        comment: (reasonObj as MessageBoxInputData).value
      };
      await todoService.reject(param);
      Message.success(translation('operationRes.operationSuccess'));
      this.dataIsEdited = true;
      this.$router.go(-1);
      TagsViewModule.DelView(this.$route);
    } catch (error) {
      if (error !== 'cancel') messageError(error);
    } finally {
      this.setApproveLoading(false);
    }
  }
  public loadDetails(id: number): void {
    this.getFinanceInvoiceInfo(id);
  }

  private setApproveLoading(value: boolean): void {
    this.approveLoading = value;
  }

  private getFinanceInvoiceInfo(id: number): void {
    financeInvoiceService
      .getById(id)
      .then(res => {
        this.invoice = res;
        if (this.invoice.invoicingTime) {
          this.invoice.expiryDate = moment(this.invoice.invoicingTime)
            .add(this.invoice.account, 'days')
            .format('YYYY-MM-DD');
        }
        this.tableOptions.data = this.invoice.billInvoiceDetailRequestList;
      })
      .catch(error => {
        messageError(error);
      });
  }

  private beforeRouteLeave(to: Route, from: Route, next: Function): void {
    to.meta!.needReload = this.dataIsEdited;
    next();
  }
}
